import React, {useEffect, useState} from 'react';
import {Button, Container, Form, Table} from "react-bootstrap";
import {
	getTelegramUsersWithGold, removeTelegramUsersGoldStatus,
	setTelegramUsersGoldStatus,
	updateAdditionalsTelegramUser
} from "../http/telegramUsersAPI";
import moment from "moment-timezone";

const GoldenList = () => {
	const [id, setId] = useState('');
	const [entities, setEntities] = useState([]);
	const [filtered, setFiltered] = useState([]);

	useEffect(() => {
		if (entities?.length === 0)
			return;

		const filtered = entities.sort((a, b) => {
			const aUntilDate = moment(a?.goldenAdditional?.untilDate);
			const bUntilDate = moment(b?.goldenAdditional?.untilDate);

			if (!aUntilDate.isValid())
				return 1;
			if (!bUntilDate.isValid())
				return -1;

			return aUntilDate.unix() - bUntilDate.unix();
		});

		setFiltered([...filtered]);
	}, [entities])

	useEffect(() => {
		getTelegramUsersWithGold()
			.then(res => setEntities([...res]))
			.catch(alert);
	}, []);


	const setGoldenStatus = () => {
		setTelegramUsersGoldStatus(id)
			.then(() => {
				getTelegramUsersWithGold()
					.then(res => setEntities([...res]))
					.catch(alert);
			})
			.catch(alert);
	}

	const saveTelegramUser = (id) => {
		const candidate = entities.find(el => el.id === id);
		updateAdditionalsTelegramUser(candidate.id, {
			description: candidate.description,
			goldenUntilDateString: candidate.goldenUntilDateString
		})
			.catch(alert);
	}

	const updateAdditionalField = (e, id, field) => {
		entities.find(el => el.id === id)[field] = e.target.value;
		setEntities([...entities]);
	}

	const removeGoldenStatus = (id) => {
		setEntities([...entities.filter(el => el.id !== id)]);
		removeTelegramUsersGoldStatus(id)
			.catch(alert);
	}

	return (
		<Container className={'mt-3 text-white text-center'}>
			<div className={'d-flex'}>
				<Form.Control
					value={id}
					onChange={e => setId(e.target.value)}
					style={{background: "#002e4e", color: "white", marginRight: 5}}
					placeholder="Введите значение"
				/>
				<Button
					onClick={setGoldenStatus}
					disabled={isNaN(id)}
				>Добавить</Button>
			</div>

			<Table bordered className={'mt-3 text-white'}>
				<thead>
				<tr>
					<th>Описание</th>
					<th>Дата окончания</th>
					<th>ID</th>
					<th>Username</th>
					<th>Имя</th>
					<th>Фамилия</th>
					<th>Сохранить</th>
					<th>Удалить</th>
				</tr>
				</thead>
				<tbody>
				{filtered.map(el =>
					<tr key={el.userId}>
						<td>
							<Form.Control
								onChange={e => updateAdditionalField(e, el.id, 'description')}
								style={{background: "#002e4e", color: "white", marginRight: 5}}
								value={el.description}
							/>
						</td>
						<td>
							<Form.Control
								onChange={e => updateAdditionalField(e, el.id, 'goldenUntilDateString')}
								style={{background: "#002e4e", color: "white", marginRight: 5}}
								value={el.goldenUntilDateString}
							/>
						</td>
						<td>
							{el.userId} ({el.userId.toString().length})
						</td>
						<td>
							{el.userName &&
								<a target={"_blank"} className={'text-white'} href={`https://t.me/${el.userName}`}>@{el.userName}</a>}
						</td>
						<td>
							{el.firstName}
						</td>
						<td>
							{el.lastName && el.lastName}
						</td>
						<td>
							<Button
								onClick={() => saveTelegramUser(el.id)}
							>Сохранить</Button>
						</td>
						<td>
							<Button
								onClick={() => removeGoldenStatus(el.id)}
								variant={'danger'}
							>Удалить</Button>
						</td>
					</tr>
				)}
				</tbody>
			</Table>
		</Container>
	);
};

export default GoldenList;